.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 24px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 3px;
    background-color: #fff;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #fff;
}
input:checked + .slider:before {
    background-color: #785ef0;
}
input:focus + .slider {
    box-shadow: 0 0 1px #fff;
}

input:checked + .slider:before {
    -webkit-transform: translateX(21px);
    -ms-transform: translateX(21px);
    transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.multiselect {
    width: 200px;
    float: left;
    position: relative;
    margin: 3px 15px;
}
.selectBox {
    position: relative;
}
.selectBox select {
    width: 100%;
    font-weight: bold;
}
.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
#checkboxes {
    display: none;
    border: 1px #dadada solid;
    position: absolute;
    background: #fff;
    z-index: 5;
    width: 100%;
    left: 0;
    padding: 0;
}

#checkboxes .filterGraph {
    margin-right: 6px;
}

#checkboxes label {
    display: block;
    padding: 4px 6px;
    margin-bottom: 0 !important;
}
#checkboxes label:hover {
    background-color: #ebeaf0;
}
.left-result {
    width: 33%;
    margin-right: 15px;
    float: left;
}
.right-graph {
    width: 64%;
    float: right;
}
.right-graph .links-ttl {
    margin-right: 30px;
}
.right-graph .links-ttl .active {
    text-decoration: underline;
    text-decoration-color: #b7abf6;
}
.right-graph a:hover {
    color: #ccc;
}
.rightcontent-sect {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
}
